import React from "react";
import "./App.css";
import Banner from "./components/Banner";
import Nav from "./components/Nav";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Skills from "./components/Skills";
import Reviews from "./components/Reviews";
import Portfolios from "./components/Portfolios";

function App() {
    return (
        <div>
            <Banner />
            <Nav />
            <Services />
            <Skills />
            <Reviews />
            <Portfolios />
            <About />
            <Contact />
        </div>
    );
}

export default App;
