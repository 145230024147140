import React, { useEffect, useState } from 'react'
import ProgressBar from './ProgressBar';
import '../_skills.css';
import axios from 'axios';
import { API_URL } from '../config';

const Skills = () => {
    const [header] = useState({
        mainHeader: "SKILLS",
        subHeading: "Our Skills",
        text: "Here you can see list of our skills",
    });

    const [skills, setSkills] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await axios.get(`${API_URL}/skills`);

            setSkills(res.data);
        })();
    }, [])

    return (
        <div className="services" id="skills">
            <div className="container">
                <div className="services__header">
                    <div className="common">
                        <h3 className="heading">{header.mainHeader}</h3>
                        <h1 className="mainHeader">{header.subHeading}</h1>
                        <p className="mainContent">{header.text}</p>
                        <div className="commonBorder"></div>
                    </div>
                </div>
                <div className="row bgMain skillsContainer">
                    {
                        skills.map((skill) => {
                            return (
                                <ProgressBar
                                    title={skill.title}
                                    width={`${skill.width}%`}
                                    text={skill.text}
                                    key={skill.id}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Skills;
