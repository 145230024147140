import React, { useState, useEffect } from 'react';
import '../_skills.css';
import axios from 'axios';
import { API_URL } from '../config';
import PortfolioItem from './PortfolioItem';

const Portfolios = () => {
    const [header] = useState({
        mainHeader: "PORTFOLIOS",
        subHeading: "Our Portfolio",
        text: "Here you can see list of our portfolios",
    });

    const [items, setItems] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await axios.get(`${API_URL}/portfolios`);

            setItems(res.data);
        })();
    }, [])

    return (
        <div className="services" id="portfolios">
            <div className="container">
                <div className="services__header">
                    <div className="common">
                        <h3 className="heading">{header.mainHeader}</h3>
                        <h1 className="mainHeader">{header.subHeading}</h1>
                        <p className="mainContent">{header.text}</p>
                        <div className="commonBorder"></div>
                    </div>
                </div>
                <div className="row bgMain" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem' }}>
                    {
                        items.map((item) => {
                            return (
                                <PortfolioItem key={item.id} portfolio={item} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default Portfolios;