/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    FaFacebookF,
    FaGithub,
    FaYoutube,
    FaPlay,
} from "react-icons/fa";
import { API_URL } from "../config";
const Banner = () => {
    // const [state] = useState({
    //     title: "I am Jonathan Doe",
    //     text:
    //         "i,m Jonathan, professional web developer with long time \
    // experience in this field​",
    //     image: "/images/man-01.png",
    // });

    const [state, setState] = useState({});

    useEffect(() => {
        (async () => {
            const res = await axios(`${API_URL}/about`);

            setState(res.data);
        })();
    }, []);

    return (
        
        <header className="header">
            <br></br>
            <br></br><br></br>

            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="header__content">
                            <div className="header__section">
                                <ul className="header__ul">
                                    <li>
                                        
                                        <a href="https://www.facebook.com/newit.com.bd/" target="_blank" style={{ color: 'white' }}>
                                            <FaFacebookF className="headerIcon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.behance.net/newitcombd" target="_blank" style={{ color: 'white' }}>
                                            <FaGithub className="headerIcon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCMa-Q9F32R2dxVQCdcgjW6Q" target="_blank" style={{ color: 'white' }}>
                                            <FaYoutube className="headerIcon" />
                                        </a>
                                    </li>
                                </ul>
                                <h1>
                                    {
                                        state.name && ` ${state.name}`
                                    }
                                </h1>
                                <p>{state.details}</p>
                                
                                <div className="header__buttons">
                                    <a href="https://www.behance.net/newitcombd" target="_blank"  className="btn btn-outline">
                                        My Portfolio
                                    </a>
                                <br></br>
                                <br></br>
                                <br></br>

                                    <a href="https://www.youtube.com/channel/UCMa-Q9F32R2dxVQCdcgjW6Q" target="_blank" className="btn btn-smart">
                                       Youtube
                                        <FaPlay className="play" />

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="banner__img">
                            <img src={state.image_path} alt="man" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Banner;
