import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_URL } from "../config";

const About = () => {
    const [header] = React.useState({
        subHeader: "About us",
        text: "Details about us",
    });
    // const [state] = React.useState([
    //     { id: 1, title: "Name:", text: "Jonathan Doe" },
    //     { id: 2, title: "Email:", text: "example@domain.com" },
    //     { id: 3, title: "Phone:", text: "+1 023 454 345" },
    //     { id: 4, title: "Linkedin", text: "Jonathan_123" },
    // ]);

    const [state, setState] = useState({});

    useEffect(() => {
        (async () => {
            const res = await axios(`${API_URL}/about`);

            setState(res.data);
        })();
    }, []);

    return (
        <div className="about" id="about">
            <div className="container">
                <div className="common">
                    <h1 className="mainHeader">{header.subHeader}</h1>
                    <p className="mainContent">{header.text}</p>
                    <div className="commonBorder"></div>
                </div>
                <div className="row h-650 alignCenter">
                    <div className="col-6">
                        <div className="about__img">
                            <img src={state.image_path} alt="me" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="about__info">
                            <h1>Hi There</h1>
                            <div className="about__info-p1">
                                {state.details}
                            </div>
                            <div className="info__contacts">
                                <div className="row">
                                    <div className="col-6">
                                        <strong>Name</strong>
                                        <p>{state.name}</p>
                                    </div>
                                    <div className="col-6">
                                        <strong>Email</strong>
                                        <p>{state.email}</p>
                                    </div>
                                    <div className="col-6">
                                        <strong>Phone</strong>
                                        <p>{state.phone}</p>
                                    </div>
                                    <div className="col-6">
                                        <strong>Address</strong>
                                        <p>{state.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
