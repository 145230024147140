import React from 'react';
import '../css/portfolio.css';

const PortfolioItem = ({ portfolio }) => {
    return (
        <div className="grid-item col-md-4">
            {
                portfolio && (
                    <div className="portfolio-content">
                        <div className="portfolio-image">
                            <a href={portfolio.link} target="_blank">
                                <img src={portfolio.image_path} alt="" />
                            </a>
                        </div>
                        <h6>{portfolio.title}</h6>
                        <p>{portfolio.description}</p>
                    </div>
                )
            }
        </div>
    )
};

export default PortfolioItem;