import React from 'react';
import '../css/reviewitem.css';

const ReviewItem = ({ review }) => {
    return (
        <div className="sc-jrAGrp dXWCyY">
            <p>{review.review}</p>
            <small>{review.name}</small>
        </div>
    )
}

export default ReviewItem;