/* eslint-disable */

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    FaGithub,
    FaCamera,
    FaCircleNotch,
    FaApple,
    FaFileVideo,
    FaSearchDollar,
} from "react-icons/fa";
import { API_URL } from "../config";
const Services = () => {
    const [header] = React.useState({
        mainHeader: "SERVICES",
        subHeading: "Our Services",
        text:
            "Here you can see list of our services we are providing",
    });

    const [state, setState] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await axios.get(`${API_URL}/services`);

            setState(res.data);
        })();
    }, []);

    return (
        <div className="services" id="services">
            <div className="container">
                <div className="services__header">
                    <div className="common">
                        <h3 className="heading">{header.mainHeader}</h3>
                        <h1 className="mainHeader">{header.subHeading}</h1>
                        <p className="mainContent">{header.text}</p>
                        <div className="commonBorder"></div>
                    </div>

                    <div className="row bgMain">
                        {state.map((info) => (
                            <div className="col-4 bgMain" key={info.id}>
                                <div className="services__box">
                                    <i className={`${info.icon} commonIcons`} aria-hidden="true"></i>
                                    <div className="services__box-header">{info.title}</div>
                                    <div className="services__box-p">{info.description}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
