import React, { useEffect, useState } from 'react'
import '../_skills.css';
import axios from 'axios';
import { API_URL } from '../config';
import '../css/reviews.css';
import ReviewItem from './ReviewItem';

const Reviews = () => {
    const [header] = useState({
        mainHeader: "Reviews",
        subHeading: "",
        text: "Here you can see list of reviews recieved from our clients",
    });

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await axios.get(`${API_URL}/reviews`);

            setReviews(res.data);
        })();
    }, []);

    return (
        <div className="services" id="reviews">
            <div className="container">
                <div className="services__header">
                    <div className="common">
                        <h3 className="heading">{header.mainHeader}</h3>
                        <h1 className="mainHeader">{header.subHeading}</h1>
                        <p className="mainContent">{header.text}</p>
                        <div className="commonBorder"></div>
                    </div>
                </div>
                <div className="row bgMain skillsContainer">
                    {
                        reviews.map((review) => {
                            return <ReviewItem review={review} key={review.id} />
                            
                        } )
                    }
                </div>
              
            </div>
        </div>
    )
}

export default Reviews;
