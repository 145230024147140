import React from "react";
import {
    FaFacebookF,
    FaGithub,
    FaYoutube,
} from "react-icons/fa";

const Contact = () => {
    return (
        <div className="contact" id="contact">
            <div className="container">
                <div className="contactSection">
                    <div className="row justifyConter">
                        <div className="col-6">
                            <div className="contactSection-logo">
                                <img src="/images/logo.png" alt="" />
                            </div>
                            <p>
                                       Get the latest news & updates
                             </p>
                            <ul className="contactCircles">
                                <li>
                                    <a href="https://www.facebook.com/newit.com.bd/" target="_blank" style={{ color: 'white' }}>
                                        <FaFacebookF className="contactIcon" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.behance.net/newitcombd" target="_blank" style={{ color: 'white' }}>
                                        <FaGithub className="contactIcon" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCMa-Q9F32R2dxVQCdcgjW6Q" target="_blank" style={{ color: 'white' }}>
                                        <FaYoutube className="contactIcon" />
                                    </a>
                                </li>
                               
                            </ul>
                            <br></br>
                            <br></br>
                        
                    <p>
                    All Right Reserved © 2020 New IT
                    </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
